/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { jsx, Flex, Heading, Paragraph, Box } from 'theme-ui';
import Action from "./shared/Action";

const ScrollRevealBanner = ({title}) => {
  const { contentfulCallToActionBanner } = useStaticQuery(graphql`
    query {
      contentfulCallToActionBanner(
        bannerTitle: {eq: "Scroll Reveal Banner"}
      ) {
        icons {
          internalName
          icon
          iconSize
          style
          url
          id
        }
      }
    }
  `);

  const { icons } = contentfulCallToActionBanner;
  const handleSocialClick = (event, pre)=> {
    event.preventDefault();
    const href = pre + window.location.href + "/";
    window.open(href, '_blank');
  };
  return (
    <Flex>
      <Box sx={{ padding: '10px 0', marginLeft:['0', 0, null, '0'] }} className="social">
        <Box>
          <Heading as="h6" sx={{fontWeight:'bold'}}>SHARE THIS ARTICLE</Heading>
        </Box>
        <Flex mt={2}>
          {icons && icons.map((icon) => (
            <Box sx={{ marginRight: "10px" }}>
            {icon.internalName === "Mail" ? 
              <a rel = "noreferrer" target = "_blank" 
                  onClick={(event)=>handleSocialClick(event, icon.url + "subject=" + title + "&body=")}
                  key={icon.id}
                  >
                  <Action icon={icon?.icon} iconSize={icon?.iconSize} style={icon?.style} actionEvent={icon?.actionEvent} label={icon?.label} footer={true}/>
              </a>
              : 
              <a rel="noreferrer" target="_blank" 
                onClick={(event)=>handleSocialClick(event, icon.url)}
                key={icon.id}
                >
                <Action icon={icon?.icon} iconSize={icon?.iconSize} style={icon?.style} actionEvent={icon?.actionEvent} label={icon?.label} footer={true}/>
              </a>
              }
              </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
export default ScrollRevealBanner;