/** @jsx jsx */
import React from 'react';
import { jsx } from "theme-ui";
import Highlight, { defaultProps } from 'prism-react-renderer';
import styled from "styled-components";
import theme from "prism-react-renderer/themes/vsDark";
import { CopyButton } from '@contentful/f36-components';

const Line = styled.div`
  display: table-row;
  width: 100%;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
  white-space: pre-wrap;
  word-break: break-word;
`;

const CustomCodeBlock = (props) => {

    const children = props.children.props ? props.children.props.children : props.children;
    const className = props.children.props ? props.children.props.className : props.className;
    const PreElement = props.children.props ? "pre" : "code";
    const isInline = props.children.props ? false : true;
    const language = 'python';

    return (
        <Highlight
            {...defaultProps}
            code={children}
            theme={theme}
            language={language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <PreElement
                    className={className}
                    sx={{
                        ...style,
                        display: isInline ? "inline-block" : "block",
                        padding: isInline ? "0px 3px" : "10px",
                        fontSize: isInline ? "12px" : "15px",
                        position: "relative",
                        borderRadius: "0.4rem",
                    }}
                >
                     {!isInline &&<CopyButton
                        tooltipProps={{ placement: "bottom", usePortal: true }}
                        value={children}
                        className="code-copy-button"
                        size="small"
                    />}
                    <code sx={{ fontSize: "15px", position: "relative" }}>
                        {tokens.map((line, i) => (
                            <Line key={i} {...getLineProps({ line, key: i })}>
                                {!isInline && <LineNo>{i + 1}</LineNo>}
                                <LineContent>
                                    {line.map((token, key) => (
                                        <span {...getTokenProps({ token, key })} />
                                    ))}
                                </LineContent>
                            </Line>
                        ))}
                    </code>
                </PreElement>
            )}
        </Highlight>
    );
};

export default CustomCodeBlock;
