import React from "react";
import { Flex, Box, Paragraph } from "theme-ui";
import Action from "./shared/Action";
import ContentfulRichText from "./shared/ContentfulRichText";

const BlogFooter = ({footerNotes, footerNotesSubtext, blogFooterButton, footerEndText}) => {
    return (
        <Box>
            {footerNotes && (
                <Box sx={{
                    display: 'flex',
                    backgroundColor: '#FEF9E9',
                    minHeight: '100px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 4,
                    mb: 1, //margin bottom
                }}
                    className="blog-content">
                    <Flex
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                            padding: "20px",
                            lineHeight: 'none'
                        }}
                    >
                        <ContentfulRichText richText={footerNotes} />
                    </Flex>
                </Box>
            )}
            {footerNotesSubtext && (
                <Box sx={{
                    display: 'flex',
                    minHeight: '100px',
                    justifyContent: 'center',
                    alignItems: 'left',
                    mt: 2,
                    mb: 2 //margin bottom
                }}>
                    <Paragraph sx={{ textAlign: 'left', fontSize: '18px', fontWeight: '900' }}>
                        {footerNotesSubtext}
                    </Paragraph>
                </Box>
            )}
            {blogFooterButton && (
                <Box sx={{
                    display: 'flex',
                    mb: 4, //margin bottom
                    mt: 2, //margin top
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                    className="hero-action-item-container">
                    <Action
                        {...blogFooterButton}
                    />
                </Box>
            )}
            {footerEndText && (
                <Box sx={{
                    display: 'flex',
                    minHeight: '100px',
                    justifyContent: 'center',
                    alignItems: 'left',
                    mt: 2,
                    mb: 2,
                    borderTop: '1px solid #dcdbdb',
                    pt: 3
                }}>
                    <Paragraph sx={{
                        textAlign: 'left',
                        fontSize: '18px',
                        fontWeight: '500',
                        color: "#a2a2a2",
                        fontStyle: "italic"
                    }}>
                        {footerEndText}
                    </Paragraph>
                </Box>
            )}
        </Box>
    );
};

export default BlogFooter;
